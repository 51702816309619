exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apoio-turista-jsx": () => import("./../../../src/pages/apoio-turista.jsx" /* webpackChunkName: "component---src-pages-apoio-turista-jsx" */),
  "component---src-pages-contrato-jsx": () => import("./../../../src/pages/contrato.jsx" /* webpackChunkName: "component---src-pages-contrato-jsx" */),
  "component---src-pages-eventos-locais-jsx": () => import("./../../../src/pages/eventos-locais.jsx" /* webpackChunkName: "component---src-pages-eventos-locais-jsx" */),
  "component---src-pages-fotos-jsx": () => import("./../../../src/pages/fotos.jsx" /* webpackChunkName: "component---src-pages-fotos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legislacao-jsx": () => import("./../../../src/pages/legislacao.jsx" /* webpackChunkName: "component---src-pages-legislacao-jsx" */),
  "component---src-pages-pontos-turisticos-jsx": () => import("./../../../src/pages/pontos-turisticos.jsx" /* webpackChunkName: "component---src-pages-pontos-turisticos-jsx" */),
  "component---src-pages-privacidade-jsx": () => import("./../../../src/pages/privacidade.jsx" /* webpackChunkName: "component---src-pages-privacidade-jsx" */),
  "component---src-pages-regras-jsx": () => import("./../../../src/pages/regras.jsx" /* webpackChunkName: "component---src-pages-regras-jsx" */),
  "component---src-pages-sobre-jsx": () => import("./../../../src/pages/sobre.jsx" /* webpackChunkName: "component---src-pages-sobre-jsx" */),
  "component---src-pages-taxas-jsx": () => import("./../../../src/pages/taxas.jsx" /* webpackChunkName: "component---src-pages-taxas-jsx" */),
  "component---src-pages-telefones-jsx": () => import("./../../../src/pages/telefones.jsx" /* webpackChunkName: "component---src-pages-telefones-jsx" */),
  "component---src-pages-termos-jsx": () => import("./../../../src/pages/termos.jsx" /* webpackChunkName: "component---src-pages-termos-jsx" */)
}

